import React from 'react'
import { Link } from 'gatsby'
import { FiChevronRight } from 'react-icons/fi'

const Feature = ({ imageUrl, title, text, link }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:block mb-12 sm:w-5/12 sm:mb-16 text-center sm:text-left">
      <img
        alt=""
        className="block mb-6 w-32 sm:w-auto sm:h-24 mx-auto sm:mx-0"
        src={imageUrl}
      />
      <div>
        <h3 className="text-lg sm:text-xl font-extrabold mb-3">{title}</h3>
        <p className="text-gray-600 leading-relaxed mb-3">{text}</p>
        {link && (
          <p>
            <Link to={link}>
              See our portfolio
              <FiChevronRight className="inline-block" />
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}

export default Feature
