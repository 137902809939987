import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Feature from '../components/Feature'
import Testimonial from '../components/Testimonial'

import design from '../images/process_design.svg'
import frontend from '../images/process_frontend.svg'
import backend from '../images/process_backend.svg'
import deployment from '../images/process_deployment.svg'
import webAgencyImg from '../images/web_agency.svg'
import testimonialBrandolini from '../images/testimonial_brandolini.png'
import logoCisl from '../images/testimonial_cisl.svg'

const WebDevelopmentPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net is a full stack web development company skilled in React, Next.js, Gatsby, Node.js applications."
        keywords={[
          `F2.net`,
          `full stack`,
          `front-end`,
          `back-end`,
          `React`,
          `Gatsby`,
          `Next.js`,
          `Node.js`,
        ]}
        title="Full stack JavaScript web app development"
      />

      <Hero>
        <h1 className="text-3xl sm:text-5xl text-center font-black leading-tight mb-3">
          What we do
        </h1>
        <p className="text-xl text-center leading-relaxed text-gray-700">
          We develop high quality web applications for small and medium
          companies, collaborating with media / frontend agencies.
        </p>
      </Hero>

      <section className="px-6">
        <div className="sm:flex flex-row flex-wrap max-w-3xl mx-auto pb-12 justify-between">
          <Feature
            imageUrl={design}
            text="We listen to you in order to thoroughly understand the problem’s domain and your requirements. Then we start designing the best solution for you and your customers."
            title="Design"
          />
          <Feature
            imageUrl={frontend}
            text="We are specialized in the development of React web applications. For public websites we use Next.js or Gatbsy, based on the type of project."
            title="Front-end Development"
          />
          <Feature
            imageUrl={backend}
            text="We use a modern technology stack and we choose the best technology for each project, to deliver the right product on time. We
            particularly care about code maintainability, performance and security."
            title="Back-end Development"
          />
          <Feature
            imageUrl={deployment}
            text="We take care of each project up to the deployment in production. We collaborate with the best cloud hosting partners to guarantee you
            performance, security and peace of mind."
            title="Deployment"
          />
        </div>
      </section>

      <section className="bg-gray-200 py-12">
        <div className="max-w-4xl mx-auto px-6 md:flex md:items-center">
          <div className="flex-1 md:pr-10 text-center md:text-left">
            <h2 className="text-3xl font-extrabold mb-3">
              Are you a web agency?
            </h2>
            <p className="text-xl leading-relaxed text-gray-700">
              We collaborate with web agencies in Italy and abroad. We may
              create complete web applications for you, or, if you take care of
              the front-end development, we may just create the back-end side of
              the moon.
            </p>
          </div>

          <div className="flex-1 hidden md:block">
            <img alt="Web agencies" src={webAgencyImg} />
          </div>
        </div>
      </section>

      <section className="px-6 pt-12 pb-20">
        <Testimonial
          authorJobTitle="IT and DPO @ CISL Lombardia"
          authorName="Donato Brandolini"
          avatarImage={testimonialBrandolini}
          logoImage={logoCisl}
          quote={
            <span>
              We are happy to work with F2.net because they{' '}
              <b>understand our needs</b> and always deliver the product we had
              in mind. They use the <b>latest technologies</b> and put much care
              in <b>user experience</b> and data <b>security</b>, improving our
              productivity with tools our users love.
            </span>
          }
        />
      </section>
    </Layout>
  )
}

export default WebDevelopmentPage
