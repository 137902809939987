import React from 'react'

const Testimonial = ({
  quote,
  authorName,
  authorJobTitle,
  avatarImage,
  logoImage,
}) => {
  return (
    <div className="flex flex-col justify-center items-center max-w-lg mx-auto">
      <div className="flex-1 text-xl leading-relaxed text-center mb-6">
        “{quote}”
      </div>
      <div className="flex items-center">
        <div>
          <img
            alt={`${authorJobTitle}`}
            className="w-8 rounded-full"
            src={avatarImage}
          />
        </div>
        <div className="ml-2">
          <div className="text-sm font-extrabold">{authorName}</div>
          <div className="text-xs">{authorJobTitle}</div>
        </div>
        {!!logoImage && (
          <div className="ml-5 pl-5 border-l border-gray-300">
            <img
              alt={`${authorJobTitle} - logo`}
              className="w-8"
              src={logoImage}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Testimonial
