import React from 'react'

const Hero = ({ children }) => {
  return (
    <div className="flex flex-col justify-center max-w-xl mx-auto px-6 py-20">
      {children}
    </div>
  )
}

export default Hero
